<template>
  <div class="requEditMain" v-loading="loadingFlag">
    <div class="header_requ">
      <div v-sticky class="header_requ" style="padding-top: 16px; background: white">
        <editHeader
          :isShow="isShow"
          :btn="btn"
          :strForm="dataForm"
          @refresh="initData"
          @openEdit="openEdit"
          @closeEdit="closeEdit"
          @submit="submit('cpttForm')"
        >
          <div slot="functionButton" class="flexV vg_ml_8">
            <div v-if="isShow">
              <el-button size="small" type="success" @click="downloadPdf">下载Excel</el-button>
              <el-button size="small" type="warning" @click="importManuscript">生成工厂打样单</el-button>
              <el-button class="vd_export1" size="small" type="warning" @click="importManuscript1">导入稿件</el-button>
              <el-button size="small" type="success" @click="printPreview">打印预览</el-button>
              <el-tooltip content="复制委托打样单号，客户简称，样品要求" effect="dark" placement="top">
                <el-button
                  v-if="$cookies.get('userInfo').dept_name === '辅料部' || $cookies.get('userInfo').stff_id === 1"
                  size="small"
                  type="warning"
                  @click="copySelectedColumnsToClipboard"
                  >辅料部专用复制
                </el-button>
              </el-tooltip>
            </div>
            <div v-if="dataForm.requ_type === '3' || $cookies.get('userInfo').stff_id === 1" class="vg_ml_8">
              <span>退回部门：</span>
              <el-select v-model="dataForm.dept_type" placeholder="请选择" size="small" style="display: inline-block; width: 150px" @change="saveFun()">
                <el-option v-for="item in deptType" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div slot="otherButton">
            <div class="flexHV vg_mr_8">
              <span>委托打样单号：</span>
              <el-input :value="dataForm.requ_no" disabled size="small" style="width: 160px"></el-input>
            </div>
          </div>
        </editHeader>
      </div>
      <el-form :model="dataForm" :inline="true" ref="formDataRef" :disabled="isShow" :rules="dataFormRule" label-width="120px">
        <contentForm
          ref="contentFormRef"
          :dataForm="dataForm"
          :statusType="true"
          @changeTableDate="changeTableDate"
          @updateFormData="updateFormData"
          :isShow="isShow"
          :userList="userList"
        />
        <el-tabs v-model="activeName" type="border-card">
          <el-tab-pane :key="'first'" label="有我司货号" name="first">
            <contentTable
              :isShowMouldNo="isShowMouldNo"
              :dataForm="dataForm"
              ref="contentTableRef"
              :isShow="isShow"
              @buttonRefresh="initData"
              :userObj="userObj"
              :requ_smpl_made_list="requ_smpl_made_list"
              :prod_unit_list="prod_unit_list"
            />
          </el-tab-pane>
          <el-tab-pane v-if="dataForm.requ_type !== '3'" :key="'second'" label="无我司货号" name="second">
            <contentTableArtNo :dataForm="dataForm" ref="contentTableArtRef" :prod_unit_list="prod_unit_list" :userList="userList" :isShow="isShow" />
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
    <el-dialog title="工厂打样需求单" :visible.sync="dialogVisible" width="80%">
      <FequList :dataForm="dataForm" @buttonRefresh="initData" @fequCancel="fequCancel"></FequList>
    </el-dialog>
    <el-dialog :title="`请选择设计单图稿`" :visible.sync="dialogVisible1" width="70%">
      <ImportManuscript
        ref="ImportManuscript"
        :dequId="dequIds"
        :propsForm="JSON.parse($UrlEncode.decode(urlKey))"
        @cancelFalse="cancelFalse1"
      ></ImportManuscript>
    </el-dialog>
    <el-row class="vg_mt_16">
      <el-col>
        <inputUser :isCookie="false" ref="userData" :stffForm="stffForm"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import FequList from './cpn/fequList.vue';
import contentForm from './cpn/contentForm.vue';
import contentTable from './cpn/contentTable.vue';
import inputUser from '@/views/component/inputUser';
import editHeader from '@/views/component/editHeaderLine';
import { get, getNoCatch, post } from '@/api/request';
import { requAPI } from '@/api/modules/requ';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import SearchTable from '@/components/table/SearchTableV2.vue';
import CostTranList from '@/views/TransportationManagement/TranManage/TabChild/Component/CostTranList.vue';
import contentTableArtNo from './cpn/contentTableArtNo.vue';
import { stffAPI } from '@api/modules/staff';
import { downloadFile, getDataCenterByPermId } from '@api/public';
import ImportManuscript from './cpn/importManuscript.vue';
import $UrlEncode from '@assets/js/UrlEncode';
import UrlEncode from '@assets/js/UrlEncode';
import { getArrayIds } from '@assets/js/arrayUtils';

export default {
  name: 'requEditMain',
  computed: {
    $UrlEncode() {
      return $UrlEncode;
    },
    dequIds() {
      return getArrayIds(this.dataForm.dataList, 'desi_id').toString();
    }
  },
  components: {
    ImportManuscript,
    CostTranList,
    SearchTable,
    contentForm,
    inputUser,
    editHeader,
    contentTable,
    contentTableArtNo,
    FequList
  },
  provide() {
    return {
      initData: this.initData
    };
  },
  data() {
    return {
      btn: {},
      isShow: true,
      loadingFlag: false,
      dataForm: {
        cust_abbr: '',
        requ_id: null, // 委托打样单id
        requ_no: null, //委托打样单 单号
        cust_id: null, //客户id
        requ_num: null, //打样数量 两位小数
        requ_pedate: null, //要求交期 date
        requ_smpl_qudate: null, //要求交期 date
        requ_sample: '0', //是 否提供原样 1是 0否
        requ_content: null, //原样要求
        requ_stff_id: null, //委托人
        requ_dept_id: null, //打样部门
        requ_quoter: null, //报价接收人
        requ_taker: null, //打样接收人
        requ_pricer: null, //核价接收人
        user_id: null,
        stff_id: null,
        dept_id: null,
        dept_team_id: null,
        dataList: [],
        dataList2: [],
        requ_type: null,
        status: false,
        requ_num_mode: null,
        sub_time: null
      },
      stffForm: {
        stff_name: '',
        cptt_name: '',
        dept_name: '',
        dept_team_name: '',
        stff_id: Number,
        cptt_id: Number,
        dept_id: Number,
        dept_team_id: Number,
        stff_this_break: null,
        stff_last_break: null,
        stff_off_hour: null
      },
      activeName: 'first',
      dataFormRule: {
        cust_id: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_type: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_num: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_pedate: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_stff_id: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_no: [{ required: true, message: ' ', trigger: 'blur' }],
        requ_content: [{ required: true, message: ' ', trigger: 'blur' }]
      },
      userObj: {},
      userList: [],
      deptType: [
        { label: '本部门', value: '本部门' },
        { label: '其他', value: '其他' }
      ],
      dialogVisible: false,
      isShowMouldNo: true,
      requ_smpl_made_list: [],
      prod_unit_list: [],
      dialogVisible1: false,
      urlKey: this.$route.query.key
    };
  },
  watch: {
    'dataForm.requ_dept_id'(val) {
      if (val) {
        getNoCatch(stffAPI.getAllStffsV1, { dept_id: val }).then(({ data }) => {
          let tempList = [];
          let tempObj = {};
          data.forEach(item => {
            let subTemp = { value: item.stff_id, label: item.stff_name };
            tempObj[item.stff_id] = subTemp;
            tempList.push(subTemp);
          });
          this.userList = tempList;
          this.userObj = tempObj;
        });
      } else {
        this.dataForm.requ_taker = null;
        this.dataForm.requ_pricer = null;
        this.dataForm.requ_quoter = null;
        this.userObj = {};
        this.userList = [];
      }
    },
    'dataForm.requ_type'(val) {
      if (val === '3') {
        this.activeName = 'first';
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    getArrayIds,
    initData() {
      this.getByIdInfo();
      this.getDataInfo();
    },
    async getDataInfo() {
      this.prod_unit_list = await getDataCenterByPermId({
        id: 10008,
        type: 'select'
      });
      this.requ_smpl_made_list = await getDataCenterByPermId({
        id: 10009,
        type: 'select',
        otherColumn: 'param2'
      });
    },
    getByIdInfo() {
      this.loadingFlag = true;
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      if (props.form_id) {
        get(requAPI.getRequById, { requ_id: props.form_id })
          .then(res => {
            if (res.data.code === 0) {
              this.btn = res.data.data.btn;
              this.dataForm.cust_abbr = res.data.data.form.cust_abbr;
              this.dataForm.requ_type = res.data.data.form.requ_type + '';
              this.dataForm.cust_id = res.data.data.form.cust_id;
              this.dataForm.cust_no = res.data.data.form.cust_no;
              this.dataForm.cust_abbr = res.data.data.form.cust_abbr;
              this.dataForm.requ_no = res.data.data.form.requ_no;
              this.dataForm.requ_num = this.helper.reservedDigits(res.data.data.form.requ_num);
              this.dataForm.requ_content = res.data.data.form.requ_content;
              this.dataForm.requ_pedate = res.data.data.form.requ_pedate * 1000;
              this.dataForm.requ_smpl_qudate = res.data.data.form.requ_smpl_qudate ? res.data.data.form.requ_smpl_qudate * 1000 : null;
              this.dataForm.requ_id = res.data.data.form.requ_id;
              this.dataForm.requ_stff_id = res.data.data.form.requ_stff_id;
              this.dataForm.requ_sample = res.data.data.form.requ_sample + '';
              this.dataForm.requ_dept_id = res.data.data.form.requ_dept_id + '';
              this.dataForm.requ_taker = res.data.data.form.requ_taker === 0 ? '' : res.data.data.form.requ_taker;
              this.dataForm.requ_quoter = res.data.data.form.requ_quoter === 0 ? '' : res.data.data.form.requ_quoter;
              this.dataForm.requ_pricer = res.data.data.form.requ_pricer === 0 ? '' : res.data.data.form.requ_pricer;
              this.dataForm.status = res.data.data.form.status;
              this.dataForm.cptt_id = res.data.data.form.cptt_id;
              this.dataForm.requ_DYJreason = res.data.data.form.requ_DYJreason;
              this.dataForm.sub_time = res.data.data.form.sub_time;
              this.dataForm.dept_type = res.data.data.form.dept_type; //退回部门
              this.stffForm.dept_name = res.data.data.form.dept_name;
              this.stffForm.dept_team_name = res.data.data.form.dept_team_name;
              this.stffForm.stff_name = res.data.data.form.stff_name;
              if (this.dataForm.status !== 0) {
                this.isShowMouldNo = false;
              }
              this.dataForm.dataList = res.data.data.form.requ_smpl_list;
              this.dataForm.dataList2 = res.data.data.form.requ_nsmpl_list;
              this.dataForm.numFlag = 1;
              this.dataForm.dataList.forEach(item => {
                // item.requ_smpl_num = this.helper.reservedDigits(item.requ_smpl_num);//需求变更，委托打样单不需要小数
                item.requ_smpl_quoter = !item.requ_smpl_quoter ? null : item.requ_smpl_quoter;
                item.requ_smpl_qudate = !item.requ_smpl_qudate ? null : Number(item.requ_smpl_qudate) * 1000;
                item.requ_smpl_pedate = !item.requ_smpl_pedate ? null : Number(item.requ_smpl_pedate) * 1000;
              });
              this.dataForm.dataList2.forEach(item => {
                item.requ_smpl_pedate = !item.requ_smpl_pedate ? null : Number(item.requ_smpl_pedate) * 1000;
              });
              setTimeout(() => {
                this.loadingFlag = false;
              }, 500);
              return;
            }
            this.$message.error(res.data.msg);
          })
          .catch(res => {
            this.$message.error(res.data.msg);
          });
      }
    },
    updateFormData(val) {
      this.formData.cust_id = val.cust_id;
      this.formData.requ_num = val.requ_num;
      this.formData.requ_pedate = val.requ_pedate;
      this.formData.requ_sample = val.requ_sample;
      this.formData.requ_stff_id = val.requ_stff_id;
      this.formData.requ_no = val.requ_no;
      this.formData.requ_dept = val.requ_dept;
      this.formData.requ_pricer = val.requ_pricer;
      this.formData.requ_content = val.requ_content;
      this.formData.requ_type = val.requ_type;
    },
    updataDataForm2(val) {
      this.dataForm.dataList = val.dataList;
      this.dataForm.dataList2 = val.dataList2;
      this.dataForm.requ_num = val.requ_num;
    },
    submit() {
      this.$confirm('确定保存？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable = await this.$refs.contentTableRef.$refs.requSmplDetail.fullValidateMsg();
          this.$refs.formDataRef.validate((valid, object) => {
            if (valid && !dynamicUTable) {
              this.saveFun();
            } else {
              let tempStr = '';
              if (dynamicUTable) tempStr += `子表-${dynamicUTable}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(() => {});
    },
    saveFun() {
      const form = {};
      form.requ_no = this.dataForm.requ_no;
      form.cust_id = this.dataForm.cust_id;
      form.requ_num = this.dataForm.requ_num;
      if (this.dataForm.requ_pedate instanceof Date) {
        form.requ_pedate = this.dataForm.requ_pedate.getTime() / 1000;
      } else {
        form.requ_pedate = this.dataForm.requ_pedate / 1000;
      }
      if (this.dataForm.requ_smpl_qudate instanceof Date) {
        form.requ_smpl_qudate = this.dataForm.requ_smpl_qudate ? this.dataForm.requ_smpl_qudate.getTime() / 1000 : null;
      } else {
        form.requ_smpl_qudate = this.dataForm.requ_smpl_qudate ? this.dataForm.requ_smpl_qudate / 1000 : null;
      }
      form.requ_sample = this.dataForm.requ_sample;
      form.requ_content = this.dataForm.requ_content;
      form.requ_stff_id = this.dataForm.requ_stff_id;
      form.requ_dept_id = this.dataForm.requ_dept_id;
      form.requ_quoter = this.dataForm.requ_quoter ? this.dataForm.requ_quoter : 0;
      form.requ_taker = this.dataForm.requ_taker ? this.dataForm.requ_taker : 0;
      form.requ_pricer = this.dataForm.requ_pricer ? this.dataForm.requ_pricer : 0;
      form.requ_type = this.dataForm.requ_type;
      form.cptt_id = this.dataForm.cptt_id;
      this.$refs.contentTableRef.delList.forEach(item => {
        let flag = this.dataForm.dataList.some(item2 => {
          item2.desi_no === item.desi_no;
        });
        if (!flag) {
          this.dataForm.dataList.push(item);
        }
      });
      this.$refs.contentTableArtRef?.delList.forEach(item => {
        let flag = this.dataForm.dataList2.some(item2 => {
          item2.requ_nsmpl_id === item.requ_nsmpl_id;
        });
        if (!flag) {
          this.dataForm.dataList2.push(item);
        }
      });
      form.requ_smpl_list = this.dataForm.dataList;
      form.requ_nsmpl_list = this.dataForm.dataList2;
      form.requ_smpl_list = form.requ_smpl_list.concat(Array.from(this.$refs.contentTableRef.subTableMap.values()));
      form.requ_smpl_list.forEach(item => {
        item.requ_smpl_pedate = this.helper.toTimeVal(item.requ_smpl_pedate);
        item.requ_smpl_qudate = this.helper.toTimeVal(item.requ_smpl_qudate);
        item.requ_smpl_quoter = !item.requ_smpl_quoter ? 0 : item.requ_smpl_quoter;
        item.requ_smpl_taker = !item.requ_smpl_taker ? 0 : item.requ_smpl_taker;
        item.requ_smpl_pricer = !item.requ_smpl_pricer ? 0 : item.requ_smpl_pricer;
        item.prod_suffix = item.prod_suffix ? item.prod_suffix : 0;
        item.requ_smpl_num = item.requ_smpl_num ? item.requ_smpl_num : 0.0;
      });
      form.requ_nsmpl_list.forEach(item => {
        item.requ_smpl_pedate = this.helper.getNewDate(item.requ_smpl_pedate);
      });
      form.requ_id = this.dataForm.requ_id;
      form.requ_num_mode = this.dataForm.requ_num_mode; //款数
      form.status = this.dataForm.status;
      form.dept_type = this.dataForm.dept_type; //退回部门
      //提交时间
      if (this.dataForm.sub_time instanceof Date) {
        form.sub_time = this.dataForm.sub_time ? this.dataForm.sub_time.getTime() : null;
      } else {
        form.sub_time = this.dataForm.sub_time ? this.dataForm.sub_time : null;
      }
      post(requAPI.editRequ, form)
        .then(res => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg);
            this.isShow = true;
            this.$emit('isShow', this.isShow);
            this.getByIdInfo();
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    closeEdit() {
      this.$confirm('确定取消编辑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
          // this.formData.fileData = []
          this.$nextTick(() => {
            this.$refs.formDataRef.resetFields();
          });
          // this.jump('/requ_list?perm_id=114');
        })
        .catch(() => {});
    },
    // 关闭弹框
    fequCancel() {
      this.dialogVisible = false;
    },
    // 生成工厂打样单
    importManuscript() {
      if (this.dataForm.status === 2) {
        this.dialogVisible = true;
      } else {
        this.$message.warning('必须生效才可导入');
      }
    },
    changeTableDate(val) {
      //有我司货号
      this.dataForm.dataList.forEach(item => {
        item.requ_smpl_pedate = val;
      });
      //无我司货号
      this.dataForm.dataList2.forEach(item => {
        this.$set(item, 'requ_smpl_pedate', val);
      });
    },
    downloadPdf() {
      this.$confirm('确定下载EXCEL?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          requAPI.export_requ_part({ requ_id: this.dataForm.requ_id }).then(({ data }) => {
            downloadFile({ fileUrl: data.pdf_url, fileName: data.fileName, type: '.xlsx' });
          });
        })
        .catch(err => console.error(err));
    },
    printPreview() {
      requAPI.export_requ_part({ requ_id: this.dataForm.requ_id, type: 1 }).then(({ data }) => {
        // data.pdf_url = data.pdf_url.indexOf('manenvip') === -1 ? process.env.VUE_APP_FILE_URL + data.pdf_url + '.xlsx' : '' + data.pdf_url + '.xlsx';
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + data.pdf_url);
      });
    },
    // 导入稿件
    importManuscript1() {
      this.dialogVisible1 = true;
    },
    // 取消导入稿件
    cancelFalse1() {
      this.dialogVisible1 = false;
    },
    async copySelectedColumnsToClipboard() {
      let text = `${this.dataForm.requ_no}\t${this.dataForm.cust_abbr}\t${this.dataForm.requ_content}`;
      try {
        await navigator.clipboard.writeText(text);
        this.$message.success('已复制到剪贴板');
      } catch (err) {
        this.$message.error('复制到剪贴板失败，请重试');
        console.error('复制到剪贴板失败:', err);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.vd_button_group {
  padding-bottom: 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid $color-secondary-text;
}

.header_requ {
  position: relative;
}

.vd_export {
  position: absolute;
  top: 0;
  left: 120px;
}

.vd_export2 {
  position: absolute;
  top: 0;
  left: 245px;
}

.vd_export3 {
  position: absolute;
  top: 0;
  left: 65px;
}

.dv_dept_type {
  position: absolute;
  top: 15px;
  right: 550px;
}
</style>
