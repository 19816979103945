<template>
  <div>
    <docu-about ref="docuAbout"></docu-about>
  </div>
</template>

<script>
import DocuAbout from '@/views/component/docuCommon/docuAbout';
import bus from '@/components/common/bus';

export default {
  name: 'DesiAddAbout',
  components: { DocuAbout },
  mounted() {
    bus.$on('refreshRequDocuList', () => {
      this.trigger();
    });
  },
  methods: {
    trigger() {
      this.$refs.docuAbout.getDocus();
    }
  }
};
</script>

<style scoped></style>
