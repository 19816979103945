<template>
  <div>
    <appr-main :isShow="isShow" @draftState="draftState" @jumpBackToDocumentInformation="$emit('jumpBackToDocumentInformation')"></appr-main>
  </div>
</template>

<script>
import apprMain from '@/views/component/apprCommon/apprMain';

export default {
  name: 'DesiEditAppr',
  components: {
    apprMain
  },
  props: {
    isShow: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    draftState() {
      this.$emit('draftState');
    }
  }
};
</script>

<style scoped></style>
