<template>
  <div>
    <el-row class="vg_mb_16">
      <div class="flexV vg_mb_5" style="padding-top: 10px">
        <div>
          <span style="display: inline-block">选择默认工厂：</span>
          <el-select v-model="supp_id" filterable placeholder="请选择" size="small" style="display: inline-block; width: 200px" @change="changeSupp">
            <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_abbr" :value="item.supp_id"> </el-option>
          </el-select>
        </div>
        <div class="vg_ml_8">
          <span style="display: inline-block">供应商编码：</span>
          <el-select v-model="supp_no" filterable placeholder="请选择" size="small" style="display: inline-block; width: 200px" @change="changeSuppID">
            <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_no" :value="item.supp_no"> </el-option>
          </el-select>
        </div>
      </div>
      <div class="vg_mt_16">
        <el-button type="primary" size="small" plain @click="confirmClick">确定生成</el-button>
        <el-button type="info" size="small" icon="el-icon-refresh-right" class="vd_export" @click="$emit('buttonRefresh')">重置</el-button>
        <el-badge :value="'已选' + selectedTableData.length" class="vg_ml_8">
          <el-button type="primary" size="small" @click="documentsDialogVisible = true">选择附件</el-button>
        </el-badge>
      </div>
    </el-row>
    <el-row>
      <el-table ref="multipleTable" :data="tableData" border style="width: 100%" tooltip-effect="dark" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column align="center" label="序号" type="index" width="50"></el-table-column>
        <el-table-column
          :formatter="row => (row.requ_nsmpl_no === '' || row.requ_nsmpl_no ? '无我司货号' : '有我司货号')"
          label="货号状态"
          align="center"
          prop="sample_status"
          sortable
          width="auto"
        >
          <template #header v-slot="scope">
            <span>货号状态</span>
            <!--            <el-select v-model="search" size="small" placeholder="请选择">
              <el-option
                v-for="item in [
                  { value: 1, label: '有货号' },
                  { value: 2, label: '无货号' },
                  { value: 0, label: '所有' }
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>-->
          </template>
        </el-table-column>
        <el-table-column align="center" label="设计单号" prop="desi_no" width="auto"></el-table-column>
        <el-table-column align="center" label="委托设计单号" prop="dequ_no" show-overflow-tooltip width="120"></el-table-column>
        <el-table-column align="center" label="我司货号" prop="prod_no" width="auto"></el-table-column>
        <el-table-column align="center" label="客户货号" prop="prod_cust_no" width="auto"></el-table-column>
        <el-table-column align="center" label="产品名称" prop="prod_name" width="auto"></el-table-column>
        <el-table-column align="right" label="样品编号" prop="requ_nsmpl_no" sortable width="120"></el-table-column>
        <el-table-column :formatter="formatterSmplNum" align="right" label="样品数量" prop="requ_smpl_num" sortable width="120"></el-table-column>
        <el-table-column align="center" label="样品单位" prop="prod_unit" width="auto"></el-table-column>
        <el-table-column :formatter="formaSmplType" align="center" label="样品类型" prop="requ_smpl_type" width="auto"></el-table-column>
        <el-table-column :formatter="formaSmplQuot" align="center" label="委托类型" prop="requ_smpl_quot" width="auto"></el-table-column>
        <el-table-column :formatter="formatterSmplTime" align="center" label="要求交样日期" prop="requ_smpl_pedate" width="120"></el-table-column>
        <el-table-column align="center" label="打样接收人" prop="requ_smpl_taker_name" width="auto"></el-table-column>
        <el-table-column align="center" label="核价接收人" prop="requ_smpl_pricer_name" width="auto"></el-table-column>
      </el-table>
    </el-row>
    <el-dialog :visible.sync="documentsDialogVisible" append-to-body title="相关文档" width="70%" @opened="getDDialogData">
      <div style="display: flex" class="vg_mb_8">
        <div style="width: 70%; display: flex">
          <el-button type="info" size="small" @click="dialogDataSelectAll">选择全部</el-button>
          <el-button type="primary" size="small" @click="dDialogConfirmSelect">移至选择列表</el-button>
          <div class="flexHV vg_ml_8">
            <span style="width: 80px">文件名:</span>
            <el-input size="small" v-model="fileName"></el-input>
          </div>
        </div>
        <div style="width: 30%; margin-left: 5px">
          <el-button type="danger" size="small" @click="deleteSelectedTableRow">删除</el-button>
        </div>
      </div>
      <div style="width: 100%; display: flex">
        <div style="width: 70%; margin-right: 5px">
          <DynamicUTable
            ref="dDialogTable"
            :table-data="dDialogTableData.filter(x => !fileName || x.docu_name.includes(fileName))"
            :need-search="false"
            :needPagination="false"
            :columns="dDialogTableProperties"
            @selection-change="selectionChange"
            @getTableData="getDDialogData"
            :rowStyle="
              ({ row, rowIndex }) => {
                row.index = rowIndex;
                return { background: row.docu_start === 1 ? '#00b1ef' : '' };
              }
            "
          ></DynamicUTable>
        </div>
        <div style="width: 30%">
          <DynamicUTable
            ref="dDialogTable2"
            :table-data="selectedTableData"
            :need-search="false"
            :needPagination="false"
            :columns="selectedProperties"
            @selection-change="val => (selectedSelection = val)"
          ></DynamicUTable>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getNoCatch, postNoCatch } from '@api/request';
import { fequAPI } from '@api/modules/fequ';
import { suppAPI } from '@api/modules/supp';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { docuAPI } from '@api/modules/docu';
import { cloneDeep } from 'lodash';
import { getDateNoTime } from '@assets/js/dateUtils';
import helper from '@assets/js/helper';
import { deleteTableRow, getArrayIds, pushPagesSequentially } from '@assets/js/arrayUtils';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'fequList',
  components: { DynamicUTable },
  data() {
    return {
      selectList: [],
      suppList: [],
      supp_id: '',
      supp_no: '',
      search: 0,
      documentsDialogVisible: false,
      dDialogTableData: [],
      dDialogTableProperties: [
        {
          prop: 'docu_url',
          itemType: 'input',
          widthAuto: true,
          label: '图片',
          input: false,
          sortable: false,
          subItem: {
            type: 'image',
            thumbnail: row => helper.picUrl(row.docu_url) + '?x-oss-process=image/resize,m_lfit,h_200,w_200',
            image: row => helper.picUrl(row.docu_url)
          }
        },
        { label: '文件名', prop: 'docu_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '格式', prop: 'docu_suffix', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '上传人', prop: 'uploaded_personnel', itemType: 'input', input: false, sortable: false, widthAuto: true },
        {
          label: '上传时间',
          prop: 'create_time',
          itemType: 'input',
          input: false,
          sortable: false,
          widthAuto: true,
          formatter: val => getDateNoTime(val, true)
        }
      ],
      selectedProperties: [
        {
          prop: 'docu_url',
          itemType: 'input',
          widthAuto: true,
          label: '图片',
          input: false,
          sortable: false,
          subItem: {
            type: 'image',
            thumbnail: row => helper.picUrl(row.docu_url) + '?x-oss-process=image/resize,m_lfit,h_200,w_200',
            image: row => helper.picUrl(row.docu_url)
          }
        },
        { label: '文件名', prop: 'docu_name', itemType: 'input', input: true, sortable: false, widthAuto: true }
      ],
      dDilaogSelection: [],
      fileName: '',
      selectedTableData: [],
      selectedSelection: [],
      selectedTableMap: new Map()
    };
  },
  props: {
    dataForm: {
      type: Object,
      required: true
    }
  },
  computed: {
    tableData() {
      return this.dataForm.dataList.concat(this.dataForm.dataList2).filter(x => x.generate_status !== 1);
    }
  },
  mounted() {
    this.getSupp();
    //this.getDDialogData();
  },
  methods: {
    // 确定生成
    confirmClick() {
      if (!this.supp_no || !this.supp_id) return this.$message.warning('请先选择默认工厂或供应商!');
      if (this.selectList.length > 0) {
        let params = {};
        params.requ_smpl_ids = getArrayIds(
          this.selectList.filter(x => !x.requ_nsmpl_no),
          'requ_smpl_id'
        );
        params.requ_nsmpl_ids = getArrayIds(
          this.selectList.filter(x => x.requ_nsmpl_no),
          'requ_nsmpl_id'
        );
        params.requ_id = this.dataForm.requ_id;
        params.user_id = this.$cookies.get('userInfo').user_id;
        params.stff_id = this.$cookies.get('userInfo').stff_id;
        params.dept_id = this.$cookies.get('userInfo').dept_id;
        params.dept_team_id = this.$cookies.get('userInfo').dept_team_id;
        params.supp_id = this.supp_id;
        params.supp_no = this.supp_no;
        params.docuPtersslist = this.selectedTableData;
        params.sub_time = this.dataForm.sub_time;
        postNoCatch(fequAPI.addFequ, params).then(({ data }) => {
          this.cancelClick();
          setTimeout(() => {
            pushPagesSequentially(data.form_id, 'FequEdit', 116, { flag: true });
            // this.jump('/fequ_edit', {
            //   key: UrlEncode.encode(
            //     JSON.stringify({
            //       perm_id: 116,
            //       form_id: data.form_id,
            //       flag: true
            //     })
            //   )
            // });
          }, 500);
        });
      } else {
        this.$message.warning('至少选择一条数据');
      }
    },
    //获取供应商信息
    getSupp() {
      getNoCatch(suppAPI.getSuppsV1).then(({ data }) => {
        this.suppList = data;
      });
    },
    // 取消生成
    cancelClick() {
      this.$emit('fequCancel');
    },
    // 勾选框选中
    handleSelectionChange(val) {
      this.selectList = val;
    },
    //数量保留位数
    formatterSmplNum(val) {
      return this.helper.reservedDigits(val.requ_smpl_num);
    },
    // 计算日期
    formatterSmplTime(val) {
      return this.helper.toTimeDay(val.requ_smpl_pedate / 1000);
    },
    // 样品类型
    formaSmplType(row) {
      if (row.requ_smpl_type === 1) {
        return '新款';
      } else if (row.requ_smpl_type === 2) {
        return '老款';
      } else if (row.requ_smpl_type === 3) {
        return '修改款';
      } else if (row.requ_smpl_type === 4) {
        return '报价';
      } else if (row.requ_smpl_type === 5) {
        return '其他';
      }
    },
    // 格式化委托类型
    formaSmplQuot(row) {
      // const delegationTypes = {
      //   1: '估价',
      //   2: '仅报价',
      //   3: '找样料',
      //   4: '找用品',
      //   5: '电绣/裁片',
      //   6: '打样并报价',
      //   7: '做文件',
      //   8: '仅打样'
      // };
      const alternativeTypes = {
        1: '打样并报价',
        2: '试样报价',
        3: '仅打样',
        4: '估价',
        5: '返工',
        6: '仅报价',
        7: '其他'
      };
      return alternativeTypes[row.requ_smpl_quot];
      // if (row.requ_nsmpl_no === '' || row.requ_nsmpl_no) {
      //   return delegationTypes[row.requ_smpl_quot];
      // } else {
      //
      // }
    },
    changeSupp(val) {
      let find = this.suppList.find(({ supp_id }) => supp_id === val);
      if (find) {
        this.supp_no = find.supp_no;
      }
    },
    changeSuppID(val) {
      let find = this.suppList.find(({ supp_no }) => supp_no === val);
      if (find) {
        this.supp_id = find.supp_id;
      }
    },
    getDDialogData() {
      let { form_id, perm_id } = JSON.parse(UrlEncode.decode(this.$route.query.key));
      let searchForm = cloneDeep(this.$refs.dDialogTable?.searchForm ?? {});
      searchForm.form_id = form_id;
      searchForm.perm_id = perm_id;
      getNoCatch(docuAPI.getDocusV1, searchForm).then(({ data }) => {
        this.dDialogTableData = data.list;
      });
    },
    dDialogConfirmSelect() {
      this.dDilaogSelection.forEach(item => {
        if (!this.selectedTableData.find(x => x.docu_pter_id === item.docu_pter_id)) {
          this.selectedTableData.push(item);
        }
      });
      this.$refs.dDialogTable.clearSelection();
    },
    dDialogTableRefresh() {
      this.$refs.dDialogTable.resetFields();
      this.getDDialogData();
    },
    selectionChange(val) {
      this.dDilaogSelection = val;
    },
    deleteSelectedTableRow() {
      let { tableData, map } = deleteTableRow('xxx', this.selectedTableData, this.selectedTableMap, this.selectedSelection);
      this.selectedTableData = tableData;
      this.selectedTableMap = map;
    },
    dialogDataSelectAll() {
      this.selectedTableData = cloneDeep(this.dDialogTableData);
      this.documentsDialogVisible = false;
    }
  }
};
</script>

<style></style>
