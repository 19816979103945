<template>
  <el-tabs v-model="activeName" class="addAndEdit" type="border-card">
    <el-tab-pane :key="'first'" label="单据信息" name="first">
      <childTab1 ref="childTab1" @isShow="isShow" />
    </el-tab-pane>
    <el-tab-pane :key="'second'" :lazy="true" label="审批流转" name="second">
      <childTab3 :isShow="isSho" @draftState="draftState" @jumpBackToDocumentInformation="changeTab" />
    </el-tab-pane>
    <el-tab-pane :key="'third'" label="相关文档" name="third">
      <childTab2 />
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import RequEditMain from './TabChild/RequEditMain';
import RequEditAbout from './TabChild/RequEditAbout';
import RequEditAppr from './TabChild/RequEditAppr';

export default {
  name: 'RequEdit',
  components: {
    childTab1: RequEditMain,
    childTab2: RequEditAbout,
    childTab3: RequEditAppr
  },
  data() {
    return {
      activeName: 'first',
      isSho: true
    };
  },
  methods: {
    isShow(val) {
      this.isSho = val;
    },
    draftState() {
      this.$refs.childTab1.initData();
    },
    changeTab() {
      this.activeName = 'first';
      this.draftState();
    }
  }
};
</script>

<style scoped>
.addAndEdit > ::v-deep .el-tabs__content {
  height: calc(100vh - 179px);
  overflow-y: auto;
  padding-top: 0;
}
</style>
